import React from "react"
import MainHeader from "../main_header/main_header"
import MainFooter from "../footer/main_footer"
import loadable from "@loadable/component"

const AboutTraining = loadable(
  () => import("./about_training/about_training"),
  {
    fallback: <div></div>,
  }
)
const CEO = loadable(() => import("./ceo_banner/ceo_banner"), {
  fallback: <div></div>,
})
const SecondBanner = loadable(() => import("./second_banner/second_banner"), {
  fallback: <div></div>,
})
const FAQs = loadable(() => import("./faqs/faqs"), {
  fallback: <div></div>,
})
const Banner = loadable(() => import("./banner/banner"), {
  fallback: <div></div>,
})

export default function BecomeTrainer() {
  return (
    <React.Fragment>
      <MainHeader />
      <Banner />

      <AboutTraining />

      <SecondBanner />
      <FAQs />
      <MainFooter />
    </React.Fragment>
  )
}
