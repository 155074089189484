import React from "react"
import BecomeTrainer from "../components/become_trainer/become_trainer"
import SEO from "../components/seo"

const Page = location => (
  <>
    <SEO
      title="Become a Trainer"
      description="Train the Trainer. We help trainers manage trainer needs. Join our training consultancy community."
      url="/become-a-trainer/"
      image="https://blogs.mycareerdreams.com/blogs/tips_header.webp"
      type="article"
    />
    <BecomeTrainer location={location} />
  </>
)
export default Page
